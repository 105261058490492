<template>
  <div
      class="flex flex-col w-full max-w-screen text-a-neutral relative font-sans tracking-wide"
  >
    <BusinessesNav class="hidden md:flex"/>
    <BusinessesNavMobile class="flex md:hidden"/>
    <MainSideMenuMobile class="flex md:hidden"/>
    <div class="flex flex-col px-4 md:px-8 lg:px-16">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>

<style></style>
